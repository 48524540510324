@font-face {
  font-family: 'Madou Futo Maru Gothic Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Madou Futo Maru Gothic Regular'), url('MadouFutoMaruGothic-d9Xo7.woff') format('woff');
}

body {
  margin: 0;
  background-background-color: #000000;
  font-family:'Madou Futo Maru Gothic Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: background-color 4s ease-in-out infinite;
}


@keyframes background-color{
  0%{
    background-color:#A0D468;
  }

  16.66%{
    background-color:#4FC1E9;
  }
  
  33.33%{
    background-color:#FFCE54;
  }
  
  50%{
    background-color:#FC6E51;
  }
  
  66.66%{
    background-color:#ED5565;
  }
  
  83.33%{
    background-color:#AC92EC;
  }

  100%{
    background-color:#A0D468;
  }
}

@-moz-keyframes background-color{
  0%{
    background-color:#A0D468;
  }

  16.66%{
    background-color:#4FC1E9;
  }
  
  33.33%{
    background-color:#FFCE54;
  }
  
  50%{
    background-color:#FC6E51;
  }
  
  66.66%{
    background-color:#ED5565;
  }
  
  83.33%{
    background-color:#AC92EC;
  }
  
  100%{
    background-color:#A0D468;
  }
}

@-webkit-keyframes background-color{
  0%{
    background-color:#A0D468;
  }

  16.66%{
    background-color:#4FC1E9;
  }
  
  33.33%{
    background-color:#FFCE54;
  }
  
  50%{
    background-color:#FC6E51;
  }
  
  66.66%{
    background-color:#ED5565;
  }
  
  83.33%{
    background-color:#AC92EC;
  }
  
  100%{
    background-color:#A0D468;
  }
}


