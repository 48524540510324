.App {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1{
  margin: 0;
}

.title {
  font-size: 13vw;
  animation-direction: reverse;
  -webkit-animation: Color 4s ease-in-out  infinite;
}

.subtitle {
  /* margin-top: -6vw; */
  font-size: 2.2vw;
  color: white;
}

@keyframes Color{
  0%{
    color:#FFCE54;
  }

  16.66%{
    color:#FC6E51;
  }
  
  33.33%{
    color:#ED5565;
  }
  
  50%{
    color:#AC92EC;
  }
  
  66.66%{
    color:#A0D468;
  }
  
  83.33%{
    color:#4FC1E9;
  }

  100%{
    color:#FFCE54;
  }
}

@-moz-keyframes Color{
  0%{
    color:#FFCE54;
  }

  16.66%{
    color:#FC6E51;
  }
  
  33.33%{
    color:#ED5565;
  }
  
  50%{
    color:#AC92EC;
  }
  
  66.66%{
    color:#A0D468;
  }
  
  83.33%{
    color:#4FC1E9;
  }

  100%{
    color:#FFCE54;
  }
}

@-webkit-keyframes Color{
  0%{
    color:#FFCE54;
  }

  16.66%{
    color:#FC6E51;
  }
  
  33.33%{
    color:#ED5565;
  }
  
  50%{
    color:#AC92EC;
  }
  
  66.66%{
    color:#A0D468;
  }
  
  83.33%{
    color:#4FC1E9;
  }

  100%{
    color:#FFCE54;
  }
}



